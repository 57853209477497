<template>
  <div id="b-protocols">
    <div class="b-protocols__bg" :style="style">
      <div class="stars">
        <div class="logo">
          <router-link class="d-flex" :to="{ name: 'dashboard' }">
            <img class="logo-img" src="@/assets/logo/white.svg" alt="Logo" />
          </router-link>
        </div>

        <div class="central-body">
          <h1 class="b-protocols__bg__title">
            {{ image }}
          </h1>
          <h2 class="b-protocols__bg__subtitle">
            {{ title }}
          </h2>

          <router-link :to="{ name: 'dashboard' }" class="btn-go-home">
            Voltar ao início
          </router-link>
        </div>
        <div class="objects">
          <img
            class="object_rocket"
            src="@/assets/protocols/rocket.svg"
            alt="IMG rocket"
            width="40px"
          />
          <div class="earth-moon">
            <img
              class="object_earth"
              src="@/assets/protocols/earth.svg"
              alt="IMG earth"
              width="100px"
            />
            <img
              class="object_moon"
              src="@/assets/protocols/moon.svg"
              alt="IMG moon"
              width="80px"
            />
          </div>
          <div class="box_astronaut">
            <img
              class="object_astronaut"
              src="@/assets/protocols/astronaut.svg"
              alt="IMG astronaut"
              width="140px"
            />
          </div>
        </div>
        <div class="glowing_stars">
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      required: true
    },

    title: {
      type: String,
      require: true
    }
  },

  computed: {
    style() {
      return `background: linear-gradient(63.41deg, #0c499e 2.99%, #558dcd 93.99%)`;
    }
  }
};
</script>

<style lang="scss">
#b-protocols {
  height: 100vh;

  @keyframes rocket-movement {
    100% {
      transform: translate(1200px, -600px);
    }
  }

  @keyframes spin-earth {
    100% {
      transform: rotate(-360deg);
      transition: transform 20s;
    }
  }

  @keyframes move-astronaut {
    100% {
      transform: translate(-160px, -160px);
    }
  }

  @keyframes rotate-astronaut {
    100% {
      transform: rotate(-720deg);
    }
  }

  @keyframes glow-star {
    40% {
      opacity: 0.3;
    }

    90%,
    100% {
      opacity: 1;
      transform: scale(1.2);
    }
  }

  .b-protocols__bg {
    height: 100%;
    overflow: hidden;

    &__title {
      font-size: 9rem;
      line-height: 8rem;
      color: #fff;
    }

    &__subtitle {
      font-size: 2rem;
      font-weight: 300;
      color: #fff;
      margin: 10px 0;
    }

    .spin-earth-on-hover {
      transition: ease 200s !important;
      transform: rotate(-3600deg) !important;
    }

    .logo {
      padding: 16px 32px;
      display: flex;
      align-items: center;
      position: fixed;

      img {
        margin-right: 8px;
      }

      p {
        margin: 0;
        color: #fff;
        font-weight: bold;
        font-size: 21px;
      }

      span {
        color: var(--v-secondary-base);
      }
    }

    .btn-go-home {
      position: relative;
      z-index: 200;
      margin: 15px auto;
      width: 200px;
      padding: 10px 15px;
      border: 1px solid var(--v-secondary-base);
      border-radius: 100px;
      font-weight: 400;
      display: block;
      color: white;
      text-align: center;
      text-decoration: none;
      letter-spacing: 2px;
      font-size: 16px;
      transition: all 0.3s ease-in;

      &:hover {
        background-color: var(--v-secondary-base);
        color: #fff;
        transform: scale(1.05);
        box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
      }
    }

    .central-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100vh;
    }

    .objects img {
      z-index: 90;
      pointer-events: none;
    }

    .object_rocket {
      z-index: 95;
      position: absolute;
      transform: translateX(-50px);
      top: 75%;
      pointer-events: none;
      animation: rocket-movement 200s linear infinite both running;
    }

    .object_earth {
      position: absolute;
      top: 20%;
      left: 15%;
      z-index: 90;
    }

    .object_moon {
      position: absolute;
      top: 12%;
      left: 25%;
    }

    .object_astronaut {
      animation: rotate-astronaut 200s infinite linear both alternate;
    }

    .box_astronaut {
      z-index: 110;
      position: absolute;
      top: 30%;
      right: 10%;
      will-change: transform;
      animation: move-astronaut 50s infinite linear both alternate;
    }

    .image-404 {
      position: relative;
      z-index: 100;
      pointer-events: none;
    }

    .glowing_stars .star {
      position: absolute;
      border-radius: 100%;
      background-color: #fff;
      width: 3px;
      height: 3px;
      opacity: 0.3;
      will-change: opacity;

      &:nth-child(1) {
        top: 80%;
        left: 25%;
        animation: glow-star 2s infinite ease-in-out alternate 1s;
      }

      &:nth-child(2) {
        top: 20%;
        left: 40%;
        animation: glow-star 2s infinite ease-in-out alternate 3s;
      }

      &:nth-child(3) {
        top: 25%;
        left: 25%;
        animation: glow-star 2s infinite ease-in-out alternate 5s;
      }

      &:nth-child(4) {
        top: 75%;
        left: 80%;
        animation: glow-star 2s infinite ease-in-out alternate 7s;
      }

      &:nth-child(5) {
        top: 90%;
        left: 50%;
        animation: glow-star 2s infinite ease-in-out alternate 9s;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .navbar-links {
      display: none;
    }

    .custom-navbar {
      text-align: center;
    }

    .brand-logo img {
      width: 120px;
    }

    .box_astronaut {
      top: 70%;
    }

    .central-body {
      padding-top: 25%;
    }
  }
}
</style>
